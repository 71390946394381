"use strict";

import {onEnterViewPort} from "@c0necto/elements-viewport-utils";
import {initInScope,onFind} from "@c0necto/elements-init-modules-in-scope";
import {findIn,removeClass, on} from '@c0necto/elements-dom-utils';

export function init () {
    onFind('.js-render-template', function (renderTemplates) {
        if (!_config.admin){
            if(renderTemplates.getAttribute('data-render-template-in-viewport') === 'true'){
                onEnterViewPort(renderTemplates, function (renderItem) {
                    renderTemplate(renderItem)
                }, { offset: 300 });
            }else{
                on('load', function() {
                    renderTemplate(renderTemplates)
                }, window);
            }
        }else{
            renderTemplate(renderTemplates);
        }

    });
}

export function renderTemplate(renderItem) {
    let template = findIn('.js-render-template__template',renderItem);

    if(template) {
        renderItem.innerHTML = template.innerHTML;
        removeClass('js-render-template',renderItem);
    }

    initInScope(renderItem);
}