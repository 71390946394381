"use strict";
import * as wishlistbundle from "@c0necto/elements-wishlist";
import {findAllIn, find, findIn, on} from "@c0necto/elements-dom-utils";

let wishlist = null;
let clearNotification = 0;

if (window['_wishlist']) {
    wishlist = wishlistbundle.createWishlist({
        ...window['_wishlist'],
        notificationOptions: {
            $container: find('.js-wishlist__notification'),
        }
    })
}

let myWishlists = [];
if (window['_wishlists']) {
    for(let i = 0; i < window['_wishlists'].length; i++){
        myWishlists.push(wishlistbundle.createWishlist({
            ...window['_wishlists'][i],
            notificationOptions: {
                $container: find('.js-wishlist__notification'),
            }
        }, {
            item: '.js-wishlist-' + i + '__btn',
            itemId: 'wishlist-id',
            wishlistId: 'wishlist-list',
            itemActiveClass: 'is-active'
        }));
    }
}

export function init() {
    if (myWishlists.length) {
        myWishlists.forEach((wishlist) => {
            wishlist.init();
        });
    }
    if(wishlist){
        wishlist.init();
    }

    const renderWishlistHandler = (evt, params) => {
        if(params.list === "wishlist-list"){
            // update counter
            let wishlistItemCounter = find('.js-wishlist__items-count');
            if (matchMedia('(max-width: 767px)').matches){
                wishlistItemCounter = findIn('.js-wishlist__items-count', find('.navbar-collapse'));
            }

            if(params.activeIds && wishlistItemCounter){
                const count = params.activeIds.length;

                wishlistItemCounter.innerHTML= count;
            }
        }

    };
    on('renderd.wishlist', (evt) => renderWishlistHandler('params', evt.detail) , document);



    const addedWishlistHandler = (evt, params) => {
        if(params.list === "wishlist-list"){
            clearNotification++;

            // $('.js-btn-profil').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
            //     $(this).removeClass('nav-btn__is-animated');
            // }).addClass('nav-btn__is-animated');

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    find('.js-alert-notification').innerHTML = '';
                }

                clearNotification--;

            }, 3500);
        }

    };
    on('added.wishlist', (evt) => addedWishlistHandler('params', evt.detail) , document);



    const removeWishlistHandler = (evt, params) => {
        if(params.list === "wishlist-list"){
            clearNotification++;

            // $('.js-btn-profil').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){
            //     $(this).removeClass('nav-btn__is-animated');
            // }).addClass('nav-btn__is-animated');

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    find('.js-alert-notification').innerHTML = '';
                }

                clearNotification--;

            }, 3500);
        }

    };
    on('removed.wishlist', (evt) => removeWishlistHandler('params', evt.detail) , document);

}

export function initInScope(scope = find('body') ) {
    if (myWishlists.length) {
        myWishlists.forEach((wishlist) => {
            wishlist.initInScope(scope);

            let wishListContainer = findAllIn('.js-wishlist__container',scope);

            wishListContainer.forEach((el) => {
                const container    = el,
                    button     = findIn('.js-wishlist__remove',container),
                    id          = container.getAttribute('data-wishlist-id');

                const buttonClickHandler = (evt, button) => {
                    evt.preventDefault();
                    let _request = wishlist.remove(id, {showUndo: true});
                    _request.then(function (res) {
                        if(res.success){
                            container.remove();
                        }
                    })
                };

                on('click', (evt) => buttonClickHandler(evt, button), button);

            });
        });

    }

    if(wishlist){
        wishlist.initInScope(scope);
    }
}

init();
initInScope(find('body'));