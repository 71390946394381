"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {findIn,removeClass,on} from '@c0necto/elements-dom-utils';

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-content-visibility'
};


export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createContentVisibility(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    },document);
}

export function createContentVisibility(item, options, selectors) {
    on('load', function() {
        removeClass('content-visibility--auto', item);
    }, window);
}