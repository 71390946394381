"use strict";

import {getPrefixedDataSet} from "@c0necto/elements-data-set-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {findIn, on} from "@c0necto/elements-dom-utils";

const defaultSelectors = {
    form: '.js-reload-form',
    loading: '.js-reload-form__loading'
};

const defaultOptions = {
    validator: () => true,
};

export function init( options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.form, function(baseElement) {
        createReloadForm(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createReloadForm(reloadForm,  options = defaultOptions, selectors = defaultSelectors) {
    on('submit', function () {
        if (typeof options.validator === "function" && options.validator(reloadForm)) {
            handleFormSubmit(reloadForm, selectors);
        }
    }, reloadForm);
}

function handleFormSubmit(form, selectors) {
    let loading = findIn(selectors.loading, form);
    if(loading) {
        loading.removeAttribute('hidden');
    }

    let dataSet = getPrefixedDataSet('reload-form', form);

    if (dataSet.disableButton !== "false") {
        let btnToDisable =  findIn('button[type="submit"], button:not([type])', form);
        if(btnToDisable) {
            btnToDisable.setAttribute('disabled', true);
        }
    }
}
