"use strict";

import {on} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init () {
    onFind('.js-history-back', function (element) {
        const clickHandler = (evt) => {
            console.log('history.length',history.length)
            console.log('document.referrer',document.referrer)
            console.log('location.protocol + "//" + location.host',location.protocol + "//" + location.host)
            if (document.referrer.indexOf(location.protocol + "//" + location.host) === 0 && history.length > 1){
                evt.preventDefault();
                window.history.back();
            }else{
                console.log('not')
            }

        };

        on('click', (evt) => clickHandler(evt), element);
    });
}