"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
import loadScript from '@c0necto/elements-load-script';

export function init () {

    onFind('.js-incert-iframe', function (element) {
        console.log('asdfsdf')
        loadScript("https://myincert.com/public/api/incertClient.js").then(() => {
            let iframeSrc = element.getAttribute('data-incert-iframe-src');
            (function(){
                let frameContainerID = 'myIncertIframe';
                incertIframe(frameContainerID, iframeSrc);
            })();
        }).catch(console.error);
    });
}