"use strict";

import initModulesInScope, {onFind, cleanUpInScope} from "@c0necto/elements-init-modules-in-scope";
import {on, findIn, empty, closest,removeClass} from "@c0necto/elements-dom-utils";
import {getApi} from "@c0necto/elements-datepicker";
import {getPrefixedDataSet} from "@c0necto/elements-data-set-utils";

const defaultSelectors = {
    base: '.js-quickfinder',
    childInputs: '.js-quickfinder__child-input',
    childContainer: '.js-quickfinder__childs'
};

export function init(selectors = defaultSelectors, template) {
    onFind(selectors.base, function (baseElement) {
        createQuickfinder(
            baseElement,
            {...defaultSelectors, ...selectors},
            template
        );
    });
}


export function createQuickfinder(quickfinder, selectors = defaultSelectors, template) {
    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    let valuesByIndex = {};
    let childContainer = findIn(selectors.childContainer, quickfinder);
    let childInputs = findIn(selectors.childInputs, quickfinder);
    let datepickerRange = closest('.js-datepicker-range', quickfinder);
    let datepickerFrom = findIn('.js-datepicker-range__from', datepickerRange);
    let datepickerTo = findIn('.js-datepicker-range__to', datepickerRange);
    let quickfinderAdults = findIn('.js-quickinder__adult-input', quickfinder);

    on('change', function(evt) {
        cleanUpInScope(childContainer);
        empty(childContainer);

        for (let i = 1; i <= evt.target.value; i++) {
            childContainer.appendChild(template({
                    element: quickfinder,
                    index: i,
                    value: valuesByIndex[i],
                    onChange: function (value) {
                        valuesByIndex[i] = value;
                    }
                })
            );
        }

        initModulesInScope(findIn(selectors.childInputs, quickfinder));

    }, childInputs);


    Promise.all([getApi(datepickerFrom), getApi(datepickerTo)]).then(function([fromApi, toApi]) {

        let fromInput = fromApi.getInput();
        const dayInMilliseconds = 24 * 60 * 60 * 1000;

        on('change', function () {
            let elementOptions = {
                ...getPrefixedDataSet('datepicker-range', datepickerRange)
            };

            if(elementOptions.customDefaultDuration) {
                toApi.setDate(roundDate(new Date(fromApi.getDate().getTime() + elementOptions.customDefaultDuration * dayInMilliseconds)));
            }

            // disable the from date in the to-datepicker

            if (fromApi.getDate() !== undefined){
                toApi.setMinDate(
                    roundDate(new Date(fromApi.getDate().getTime() + 1 * dayInMilliseconds))
                );
            }else{
                findIn('.js-datepicker__input', datepickerTo).value = '';
                findIn('.js-datepicker__input', datepickerFrom).value = '';

                removeClass('has-value',datepickerFrom);

                // set to default value of 2
                quickfinderAdults.value = 2;

            }

        }, fromInput);
    });

}

function roundDate(date) {
    if (date) {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }
}
