"use strict";

import {findIn, addClass, removeClass, closest, findAllIn, on, findAll} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init () {
        const regionMapItemClickHandler = (evt, regionMapItem) => {
        evt.preventDefault();
        let regionMapWrapper = closest('.js-region-map-custom-links', regionMapItem);

        if(regionMapWrapper){
            let name = regionMapItem.dataset.name;
            let regionMapLinks = findAllIn('.js-region-map-custom-links__link',regionMapWrapper);

            regionMapLinks.forEach((regionLink) => {
                let regionLinkId = regionLink.getAttribute('data-region-id');
                if(name === regionLinkId){
                    window.location.href = regionLink.getAttribute('href');
                }
            });

        }else if(regionMapItem.dataset.href !== '#'){
            let itemHref = regionMapItem.dataset.href;
            window.location.href = itemHref;
        }

    };

    onFind('.region-map__item', function (regionMapItem) {
        on('click', (evt) => regionMapItemClickHandler(evt, regionMapItem), regionMapItem);
    });

    initFirstRegion();

    onFind('.region-map__item-map', function (interactiveRegionMapItem) {
        const interactiveRegionMapMouseoverHandler = (evt) => {
            let thisItem = evt.target,
                interactiveRegionMap = closest('.js-interactive-region-map', thisItem);

            if (interactiveRegionMap){

                let thisItemRegionMapId = thisItem.getAttribute('data-name'),
                    allInteractiveTextItems = findAllIn('.js-interactive-region-map__text-item', interactiveRegionMap),
                    allInteractiveMapItems = findAllIn('.region-map__item-map', interactiveRegionMap);

                allInteractiveMapItems.forEach((regionMapItem) => {
                    removeClass('is-active', regionMapItem);
                });

                allInteractiveTextItems.forEach((regionItem) => {
                    let thisItemRegionTextId = regionItem.getAttribute('data-region-id');
                    removeClass('is-active', closest('.js-interactive-region-map__item', regionItem));

                    if(thisItemRegionMapId === thisItemRegionTextId){
                        addClass('is-active',closest('.js-interactive-region-map__item', regionItem));
                    }
                });

                addClass('is-active', thisItem);
            }
        };

        on('mouseover', (evt) => interactiveRegionMapMouseoverHandler(evt), interactiveRegionMapItem);
        on('mouseover', (evt) => interactiveRegionMapMouseoverHandler(evt), interactiveRegionMapItem);


        const interactiveRegionMapClickHandler = (evt) => {
            let thisItem = evt.target,
                interactiveRegionMap = closest('.js-interactive-region-map', thisItem);

            if (interactiveRegionMap){

                let thisItemRegionMapId = thisItem.getAttribute('data-name'),
                    allInteractiveTextItems = findAllIn('.js-interactive-region-map__text-item', interactiveRegionMap),
                    allInteractiveMapItems = findAllIn('.region-map__item-map', interactiveRegionMap);

                allInteractiveTextItems.forEach((regionItem) => {
                    let thisItemRegionTextId = regionItem.getAttribute('data-region-id');

                    if(thisItemRegionMapId === thisItemRegionTextId){
                        window.location.href = regionItem.getAttribute('href');
                    }
                });

            }

        };

        if(matchMedia('(min-width: 768px)').matches && !matchMedia('(pointer: coarse)').matches || matchMedia('(max-width: 767px)').matches) {
            on('click', (evt) => interactiveRegionMapClickHandler(evt), interactiveRegionMapItem);
        }

        // if(matchMedia('(min-width: 1025px)').matches || matchMedia('(min-width: 1025px)').matches) {
        //     on('click', (evt) => interactiveRegionMapClickHandler(evt), interactiveRegionMapItem);
        // }

    });

}

function initFirstRegion() {
    onFind('.js-interactive-region-map', function (regionMap) {
        let firstRegionInMap = findIn('.region-map__item-map',regionMap);
        addClass('is-active', firstRegionInMap);

        let allInteractiveTextItems = findAllIn('.js-interactive-region-map__text-item', regionMap),
            thisItemRegionMapId = firstRegionInMap.getAttribute('data-name');

        allInteractiveTextItems.forEach((regionItem) => {
            let thisItemRegionTextId = regionItem.getAttribute('data-region-id');
            removeClass('is-active', closest('.js-interactive-region-map__item', regionItem));

            if(thisItemRegionMapId === thisItemRegionTextId){
                addClass('is-active',closest('.js-interactive-region-map__item', regionItem));
            }
        });
    });
}