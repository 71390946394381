"use strict";

import probaClick from 'probaclick';
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {findAll} from "@c0necto/elements-dom-utils";

let hasBeen = {
    prefetch: [],
    prerender: [],
};

const makeHint = (href, type) => {
    let link = document.createElement("link");
    link.setAttribute("rel", type);
    link.setAttribute("href", href);
    document.head.appendChild(link);
    return href;
};

const isExternalLink = (href) => {
    return !href.match(/^\//) && !href.includes(window.location.host);
};

export const maybeMakeHint = ({ href, type, max = null } = {}) => {
    if (isExternalLink(href)) return;
    if (hasBeen[type].includes(href)) return;
    if (max !== null && hasBeen[type].length >= max) return;

    hasBeen[type].push(makeHint(href, type));
};

const getLink = element => element.tagName === 'A'
    ? element
    : element.querySelector('a:not([href="javascript://"]):not([href^="#"])');


export function init ($scope) {

    // let elements = findAll('.js-hover-prefetch');

    onFind('.js-hover-prefetch', function (elements) {
        // probaClick watches for hover, therefore it only triggers on desktop
        probaClick(elements, {
            callback: function (element) {
                maybeMakeHint({
                    href: getLink(element).getAttribute("href"),
                    type: "prefetch",
                });
            },
        });

        probaClick(elements, {
            delay: 1000,
            count: 3,
            callback: function (element) {
                maybeMakeHint({
                    href: getLink(element).getAttribute("href"),
                    type: "prerender",
                    max: 1,
                });
            },
        });

        Array.from(elements).forEach(element => {
            // Prefetch indicator detection for mobile
            element.addEventListener('click', function () {
                maybeMakeHint({
                    href: getLink(element).getAttribute("href"),
                    type: "prefetch",
                });
            }, true);

            // in case there is a slick slider that prevents clicks
            $(element).on('beforeChange', function () {
                maybeMakeHint({
                    href: getLink(element).getAttribute("href"),
                    type: "prefetch",
                });
            })
        });
    });


}

