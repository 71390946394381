"use strict";

import {findIn} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {

    onFind('.js-image-swiper', function (sliderElement) {

        createSwiper(sliderElement,{
                // mediaQuery: '(max-width: 767px)',
                slidesPerView: 1.3,
                spaceBetween: 20,
                loop: false,
                centeredSlides: false,
                pagination: {
                    el: swiperContainer => findIn('.js-image-swiper__pagination', swiperContainer),
                    clickable: true
                },
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-image-swiper__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-image-swiper__prev', swiperContainer),
                },
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 3,
                    }
                },
                on: {
                    init: function () {
                        setTimeout(() => {
                            window.dispatchEvent(new CustomEvent("resize", {bubbles: true}));
                        }, 200)
                    },
                }
            },
            {
                sliderContainer: '.js-image-swiper',
                slider: '.js-image-swiper__slider'
            });

    });

}