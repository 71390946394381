"use strict";

import {closest, find, on, addClass, removeClass, hasClass, findAll} from '@c0necto/elements-dom-utils'
import {translate} from '@c0necto/elements-translations';
import * as quickfinder from './quickfinder';
import initModulesInScope, {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init() {
    quickfinder.init(undefined,
        renderChildAgeInput
    );

    function renderChildAgeInput({element, index, value, onChange}) {
        let markupElement = document.createElement('div');

        let quickfinderWrapper = closest('.js-quickfinder__room', element);
        let roomCounter = quickfinderWrapper.getAttribute('data-item-room-count');

        let markup = `<div class="col-md-4 col-6" id="quickfinder-child-age-${index}">` +
            `<div class="select form-group">` +
            `<div class="position-relative">` +
            `<label class="form-control-label floating-label" for="childAge${index}"> ${translate('form.childAge')} ${index}</label>` +
            `<select id="childAges${roomCounter-1}-${index}" name="childAge${roomCounter-1}-${index}" class="form-control js-quickfinder__child-age-input js-floating-label" required>` +
            `<option  value="" selected disabled>${translate('form.Bitte auswählen')}</option>`;

        for (let i = 0; i <= 16; i++) {
            markup += `<option value="${i}" ${i === value ? 'selected': ''}>${i} ${translate('form.Jahre')}</option>`;
        }

        markup += `</select>` +
            `</div>`+
            `<div class="form-errors invalid-feedback"></div>` +
            `</div></div>`;

        markupElement.innerHTML = markup;
        let quickfinderItem = markupElement.childNodes[0];    //remove wrapper-div

        quickfinderItem.addEventListener('change', function(e) {
            removeClass('has-error', closest('.form-group', e.target));

            onChange(e.target.value);
        });

        return quickfinderItem;
    }

    onFind('.js-quickfinder__dropdown', function (element) {

        if(matchMedia('(max-width: 767px)').matches){

            const dropdownClickHandler =  (evt,quickfinderDropdown) => {
                evt.preventDefault();

                let quickfinderWrapper = closest('.js-quickfinder-wrapper', quickfinderDropdown);

                if(hasClass('is-open', quickfinderWrapper)){
                    console.log('remove')
                    removeClass('is-open', quickfinderWrapper);
                }else{
                    console.log('add')

                    addClass('is-open', quickfinderWrapper);
                }
            };

            on('click', (evt) => dropdownClickHandler(evt, element), element);

        }

        element.parentNode.addEventListener('hidden.bs.dropdown', function(event){
            let childAgeInputs = findAll('.js-quickfinder__child-age-input');

            childAgeInputs.forEach((input) => {
                let value = input.value;

                if(value === ''){
                    find('.js-quickfinder__child-error-message').removeAttribute('hidden');
                    addClass('has-error', closest('.form-group', input));
                }else{
                    find('.js-quickfinder__child-error-message').setAttribute('hidden', true)
                    removeClass('has-error', closest('.form-group', input));
                }
            });

        }, false);
    });


}
