"use strict";

import Popover from 'bootstrap.native/dist/components/popover-native.esm';
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {addClass,removeClass} from "@c0necto/elements-dom-utils";

export function init() {

    let dismissable = false;
    if(matchMedia('(max-width: 767px)').matches){
        dismissable = true;
    }
    onFind('.js-popover', function (element) {
        setPopover(element, {
            placement: 'top',
            dismissible: dismissable
        });

    });

    onFind('.js-popover-wishlist',function (element) {
        setPopover(element, {
            trigger: 'click',
            dismissible: true
        });
    });
}

export function setPopover(element, options) {

    element.addEventListener('show.bs.popover', function(event){
        addClass('show-popover', event.target);

        onFind('.show-popover',function (element) {
            // close other popover
            if (element !== event.target){
                new Popover(element,options).hide();
                removeClass('show-popover', element);
            }
        });

        window.addEventListener("click", function bodyClickListener(evt) {
            if (!event.target.contains(evt.target)) {
                onFind('.show-popover',function (element) {
                    new Popover(element,options).hide();
                });

                window.removeEventListener("click", bodyClickListener, true);
            }
        }, true);
    }, false);

    new Popover(element,options);

}