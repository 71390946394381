"use strict";

import {findAll, on, closest, trigger, findIn} from "@c0necto/elements-dom-utils";

export function init () {

    let videosStarts = findAll('.js-video__start');

    const videoClickHandler = (evt, video) => {
        let videoLink = findIn('.js-video__video-link',closest('.js-video',video));
        console.log('videolink', videoLink)
        trigger('click', videoLink);
    };

    videosStarts.forEach((video) => {
        on('click', (evt) => videoClickHandler(evt, video), video);
    });

}