"use strict";

import * as swiper from "./swiper";
import {findIn} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {

    onFind('.js-acco-teaser-swiper', function (sliderElement) {

        createSwiper(sliderElement,{
                // mediaQuery: '(max-width: 767px)',
                loop: false,
                slidesPerView: 1.2,
                spaceBetween: 20,
                pagination: {
                    el: swiperContainer => findIn('.js-acco-teaser-swiper__pagination',sliderElement),
                    clickable: true
                },
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                        allowTouchMove:false
                    }
                },
                on: {
                    init: function () {
                        setTimeout(() => {
                            window.dispatchEvent(new CustomEvent("resize", {bubbles: true}));
                        }, 200)
                    },
                }
            },
            {
                sliderContainer: '.js-swiper-acco-teaser-swiper',
                slider: '.js-acco-teaser-swiper__slider',
                sliderPrev: '.js-acco-teaser-swiper__prev',
                sliderNex: '.js-acco-teaser-swiper__next'
            })

    });


}