"use strict";

import {find, findAll, findAllIn,findIn, addClass, removeClass, on, hasClass} from '@c0necto/elements-dom-utils'
import {onFind} from "@c0necto/elements-init-modules-in-scope";

const defaultSelectors = {
    nav: '.js-nav',
    item: '.js-nav__main-item',
    subnav: '.js-nav__subnav'
};

export function init( selectors = defaultSelectors) {
    onFind(selectors.nav, function (baseElement) {
        createMenuAim(
            baseElement,
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createMenuAim(nav, selectors = defaultSelectors) {

    if(matchMedia('(min-width: 768px)').matches) {

        let timeout = 200;
        let timer;

        let $items = findAllIn(selectors.item, nav);

        $items.forEach((navItem) => {
            if(window.matchMedia("(pointer: coarse)").matches) {
                navItem.addEventListener('click', e => {

                    if (hasClass('clicked', e.target)){
                        removeClass('clicked', e.target);
                    }else{
                        e.preventDefault();
                        addClass('clicked', e.target);
                    }
                });
            }

            navItem.addEventListener('mouseenter', e => {
                e.stopPropagation();
                e.stopImmediatePropagation();

                clearTimeout(timer);

                timer = setTimeout(function() {

                    $items.forEach((navItem) => {
                        removeClass('is-open', navItem);
                    });
                    addClass('is-open',navItem)

                    // close subnavi on scroll
                    window.addEventListener("scroll", function bodyScrollListener(evt) {
                        removeClass('is-open',navItem);
                        window.removeEventListener("scroll", bodyScrollListener, true);
                    }, true);

                }, timeout);

            });


            navItem.addEventListener('mouseleave', e => {
                clearTimeout(timer);

                timer = setTimeout(function() {
                    removeClass('is-open',navItem);
                }, timeout);
            });
        });

    }
}
