"use strict";

import {findAll, on} from "@c0necto/elements-dom-utils";

export function init () {
    let dropdowns = findAll('.js-keep-dropdown-open');

    const $dropdownClickHandler = (evt) => {
        evt.stopPropagation();
    };

    dropdowns.forEach((dropdownItem) => {
        on('click', (evt) => $dropdownClickHandler(evt), dropdownItem);
    });

}