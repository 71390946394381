"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {on} from "@c0necto/elements-dom-utils";


export function init() {
    onFind('.js-cookiehub__open-modal', function (element) {
        on('click', (evt) => {
            evt.preventDefault();
            window.cookiehub.openSettings()
        } , element);
    });
}