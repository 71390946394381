"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init() {
    onFind('.js-svg-converter', function (svgItem) {
        let elem = svgItem;
        let svgFileString = elem.innerHTML;

        let svgData = elem.dataset.json;
        let attributes = [];

        if (svgData) {
            if (!window['_svgTransform']) {
                console.error('_svgTransform is not set');
            } else if (!window._svgTransform[svgData]) {
                console.error(svgData + ' is not set in _svgTransform', _svgTransform);
            } else {
                attributes = _svgTransform[svgData];
            }


            for (let key in attributes) {
                let replaceStr = "#"+key+"#";
                svgFileString = svgFileString.replace(new RegExp(replaceStr, "g"), attributes[key]);
            }

            elem.innerHTML = svgFileString;
        }
    });
}
