"use strict";

import {empty, findAllIn, find, on} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import loadScript from '@c0necto/elements-load-script';
import fetch from '@c0necto/elements-fetch';
import {showNotification} from "@c0necto/elements-alert-notification";
import 'url-polyfill';
import 'url-search-params-polyfill';

export function init () {
    onFind('.js-aa4y-basic-enquiry', function (element) {
        loadScript("//live.anfrageassistent4you.com/js/anfrage.js").then(() => {
            fetchKognitivFilterIds(element, _config.filterIdsRequestUrlBasic);
        }).catch(console.error);
    });
}

export function fetchKognitivFilterIds(element, url){
    let pendingRequest = fetch(url);
    let enquiryLoading = find('.js-aa4y-basic-enquiry__loading', element);
    let aa4yFormType = element.getAttribute('data-aa4y-form-type') || 'pool';

    enquiryLoading.removeAttribute('hidden');


    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if (res.success) {
            let filterKognitivIds = res.filterKognitivIds[0];

            let _lang = _config.lang;
            if (_config.lang === 'it'){
                _lang = 'en';
            }

            let options = {
                form_type: aa4yFormType,
                lang: _lang,
                form_style: []
            }

            options = {...options, ...filterKognitivIds};
            new Anfrage('aa4y-basic', options);

            enquiryLoading.setAttribute('hidden', 'hidden');

        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });
}