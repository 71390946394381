"use strict";

import {find, findAll, addClass, removeClass, on, hasClass, closest} from '@c0necto/elements-dom-utils';
import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init() {

    if(matchMedia('(min-width: 768px)').matches){
        desktopNav();
    }else{
        mobileNav();
    }
}

function desktopNav() {
    let navItems = findAll('.js-nav__main-item');

    let timeout = 200;
    let timer;
    navItems.forEach((navItem) => {
        navItem.addEventListener("mouseenter", function( event ) {
            clearTimeout(timer);
            timer = setTimeout(function() {
                addClass('is-open',find('.navbar-main'));
            }, timeout);

        }, false);

        navItem.addEventListener("mouseleave", function( event ) {
            clearTimeout(timer);
            timer = setTimeout(function() {
                removeClass('is-open',find('.navbar-main'));
            }, timeout);
        }, false);
    });

}

function mobileNav() {

    let navToggler = find('.js-nav__toggle');
    let body = find('body');
    if (navToggler){
        const navTogglerHandler = evt => {
            evt.preventDefault();

            if(hasClass('is-open', navToggler)){
                removeClass('is-open', navToggler);
                removeClass('nav-open', body);
            }else{
                addClass('is-open', navToggler);
                addClass('nav-open', body);
            }
        };

        on('click', navTogglerHandler, navToggler);

        onFind('.js-nav__item-toggle', function (navLinkToggler) {

            const navLinkTogglerHandler = (evt, navLinkItem) => {
                evt.preventDefault();

                let navItem = closest('.js-nav__item',navLinkItem);

                if(hasClass('is-open', navItem)){
                    removeClass('is-open', navItem);
                }else{
                    addClass('is-open', navItem);
                }
            };

            on('click', (evt) => navLinkTogglerHandler(evt, navLinkToggler), navLinkToggler);
        });

    }

}