"use strict";

import {empty, findAllIn, find, on} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import loadScript from '@c0necto/elements-load-script';
import fetch from '@c0necto/elements-fetch';
import {showNotification} from "@c0necto/elements-alert-notification";
import 'url-polyfill';
import 'url-search-params-polyfill';

export function init () {
    onFind('.js-aa4y-single-enquiry', function (element) {
        let enquiryBtns = findAllIn('.js-aa4y-single-enquiry__btn', element);

        loadScript("//live.anfrageassistent4you.com/js/anfrage.js").then(() => {

            const enquiryBtnClickHandler = (evt, btn) => {
                fetchKognitivFilterIds(element, _config.filterIdsRequestUrlSingle);
            };

            enquiryBtns.forEach((btn) => {
                on('click', (evt) => enquiryBtnClickHandler(evt, btn), btn);
            });

        }).catch(console.error);

    });
}

export function fetchKognitivFilterIds(element, url){
    let pendingRequest = fetch(url);
    let accoKognitivId = element.getAttribute('data-aa4y-kognitiv-id');
    let accRoomType = element.getAttribute('data-aa4y-kognitiv-roomtype') || null;
    let accRoomCatering = element.getAttribute('data-aa4y-catering') || null;
    let accRoomFlatrate = element.getAttribute('data-aa4y-flaterate') || null;
    let accRoomKognitivCode = element.getAttribute('data-aa4y-kognitiv-code') || null;
    let accRoomOfferTypeCode = element.getAttribute('data-aa4y-offer-type-code') || null;
    let aa4yFormType = element.getAttribute('data-aa4y-form-type') || 'individuell';
    let enquiryLoading = find('.js-aa4y-single-enquiry__loading', element);
    let enquiryResult = find('.js-aa4y-single-enquiry__result', element);
    let enquiryNotification = find('.js-aa4y-single-enquiry__notification');

    enquiryLoading.removeAttribute('hidden');

    empty(enquiryResult);

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if (res.success) {
            let filterKognitivIds = res.filterKognitivIds[0];

            let _lang = _config.lang;
            if (_config.lang === 'it'){
                _lang = 'en';
            }

            let options = {
                form_type: aa4yFormType,
                lang: _lang,
                kognitivId: accoKognitivId,
                roomTypeCode: accRoomType,
                roomCatering: accRoomCatering,
                roomFlatrate: accRoomFlatrate,
                roomKognitivCode: parseInt(accRoomKognitivCode),
                roomOfferTypeCode: accRoomOfferTypeCode,
                form_style: []
            }

            options = {...options, ...filterKognitivIds};

            new Anfrage('aa4y-einfach', options);

            enquiryLoading.setAttribute('hidden', 'hidden');

        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);

            showNotification({
                type: 'danger',
                content: 'Error. Please Try again',
                closable: false,
                $container: enquiryNotification

            });
            enquiryLoading.setAttribute('hidden', 'hidden');
        }
    });
}