import "@c0necto/elements-dom-utils/polyfills/ie";

import {onFind} from "@c0necto/elements-init-modules-in-scope";

import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm';
onFind('.js-tooltip',function (element) {
    new Tooltip(element);
});

import Alert from 'bootstrap.native/dist/components/alert-native.esm';
onFind('[data-dismiss="alert"]',function (element) {
    new Alert(element);
});

import Dropdown from 'bootstrap.native/dist/components/dropdown-native.esm';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import Modal from 'bootstrap.native/dist/components/modal-native.esm';
onFind('[data-toggle="modal"]',function (element) {
    new Modal(element);
});

import * as formValidation from './form-validation';
formValidation.init();

import * as toc from '@c0necto/elements-toc';
toc.init();

import * as lazyImg from '@c0necto/elements-lazy-img';
lazyImg.init({preventNative: true});

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as navigation from './navigation';
navigation.init();

import * as affixNav from './affix-nav';
affixNav.init();

import * as menuAim from './menu-aim';
menuAim.init();

import * as floatingLabels from '@c0necto/elements-floating-labels';
floatingLabels.init();

import * as affix from '@c0necto/elements-affix';
affix.init();

import * as svgConverter from './svg-converter';
svgConverter.init();

import * as customTracking from './custom-tracking';
customTracking.init();

import * as wishlist from './wishlist';
wishlist.init();
wishlist.initInScope();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as interactiveRegionMap from './interactive-region-map';
interactiveRegionMap.init();

import * as aa4ySingleEnquiry from './aa4y-single-enquiry';
aa4ySingleEnquiry.init();

import * as aa4yBasicEnquiry from './aa4y-basic-enquiry';
aa4yBasicEnquiry.init();

import * as cookiehub from './cookiehub';
cookiehub.init();

import * as incertIframe from './incert-iframe';
incertIframe.init();

import * as modalSearchFocus from './modal-search-focus.js';
modalSearchFocus.init();

import * as tracking from './tracking.js';
tracking.init();

import * as video from './video.js';
video.init();

import * as hoverPrefetch from './hover-prefetch.js';
hoverPrefetch.init();

import * as historyBack from './history-back.js';
historyBack.init();

import * as togglePassword from './form-toggle-password';
togglePassword.init();