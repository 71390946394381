"use strict";

import {onEnterViewPort} from "@c0necto/elements-viewport-utils";
import {initInScope,onFind} from "@c0necto/elements-init-modules-in-scope";
import {findIn,removeClass} from '@c0necto/elements-dom-utils';

let _skd = window._skd || {};
_skd.callbacks = _skd.callbacks || {};
_skd.callbacks.dsr = _skd.callbacks.dsr || {};

let trackingState = '';
let trackingAccommodationType = '';

if(_tracking.trackingCustom.state){
    trackingState = _tracking.trackingCustom.state;
}

if(_tracking.trackingCustom.accommodationType){
    trackingAccommodationType = _tracking.trackingCustom.accommodationType;
}

export function init () {

    _skd.callbacks.dsr.searchBtnClick = function(data) {
        ga('send', 'pageview', 'seekda Perform Search');  // send pageview if searchBtnClicked

        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.send';
            ga(gaEventState, 'pageview', 'seekda Perform Search');
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.send';
            ga(gaEventAccoType, 'pageview', 'seekda Perform Search');
        }

    }

    _skd.callbacks.dsr.viewOffers = function(data) {
        ga('send', 'pageview', 'seekda view offers');  // send pageview when offers are shown

        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.send';
            ga(gaEventState, 'pageview', 'seekda view offers');
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.send';
            ga(gaEventAccoType, 'pageview', 'seekda view offers');
        }
    }

    _skd.callbacks.dsr.viewRoom = function(data) {
        ga('send', 'pageview', 'seekda view room'); // send pageview when user selects a room

        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.send';
            ga(gaEventState, 'pageview', 'seekda view room');
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.send';
            ga(gaEventAccoType, 'pageview', 'seekda view room');
        }
    }

    _skd.callbacks.dsr.viewPackage = function(data) {
        ga('send', 'pageview', 'seekda view package');  // send pageview when user selects a package

        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.send';
            ga(gaEventState, 'pageview', 'seekda view package');
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.send';
            ga(gaEventAccoType, 'pageview', 'seekda view package');
        }

    }

    _skd.callbacks.dsr.viewOfferDetails = function(data) {
        ga('send', 'pageview', 'seekda view offer details');  // send pageview when offer details are shown

        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.send';
            ga(gaEventState, 'pageview', 'seekda view offer details');
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.send';
            ga(gaEventAccoType, 'pageview', 'seekda view offer details');
        }
    }

    _skd.callbacks.dsr.viewPersInfo = function(data) {
        ga('send', 'pageview', 'seekda view personal information');  // send pageview when personal info entry step is loaded

        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.send';
            ga(gaEventState, 'pageview', 'seekda view personal information');
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.send';
            ga(gaEventAccoType, 'pageview', 'seekda view personal information');
        }
    }

    /*    ! ! !
    !!! obsolete  !!!
    _skd.callbacks.dsr.viewReview = function(data) {
      ga('send', 'pageview', 'seekda view review');  // send pageview when review step is loaded
    }

          ! ! !   */


    ecommerceTracking();
}


function ecommerceTracking(){
    _skd.callbacks.dsr.viewConfirmation = function(data) {

        console.log("skd Log ", data); // check general output
        console.log("skd Log GA eCommerce resID", data.reservationId); // check if resId is generated
        console.log("skd Log GA eCommerce total inkl", data.total); // check if total is generated

        ga('send', 'pageview', 'seekda viewConfirmation')

        if (trackingState !== '') {
            let gaEventState = trackingState.toLowerCase() + '.send';
            ga(gaEventState, 'pageview', 'seekda viewConfirmation');
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.send';
            ga(gaEventAccoType, 'pageview', 'seekda viewConfirmation');
        }

        var i = 0,
        date = new Date(Date.parse(data.searchData.endDate) - Date.parse(data.searchData.startDate)), // parsing the date for quantity length of stay calc
        quantity = (date.getDate() > 1) ? date.getDate() - 1 : 1; // calculating the quantity, length of stay

        console.log("skd Log GA eCommerce quantity", quantity); // check if quantity calc is correct

        var skdtransaction = {                            // define transaction
            'id': data.reservationId,                    // Transaction ID. Required.
            'affiliation': 'seekda Dynamic Shop',        // Affiliation or store name.
            'revenue': data.total,                        // Grand Total.
            'shipping': 0,                                // Shipping.
            'tax': 0,                                    // Tax.
            'currency': data.searchData.currencyCode    // Currency.
        };

        var items = [];
        for (i = 0; i < data.searchData.rooms.length; i++) {
            //adding r transactionitems depending on how many rooms are booked.

            var room = data.searchData.rooms[i];

            items.push({
                'id': data.reservationId, // Transaction ID in seekda Reservation ID. Required.
                'name': data.selectedRateCodes[i], // prodcut name are ratecodes. Required.
                'sku': data.selectedRoomCodes[i], // sku are roomcodes
                'category': 'Adults: ' + room.adults + ', Children: ' + room.children.total, // category is adults and children
                'price':    room.roomTotal/quantity,  // send price for each room devided by quantity
                'quantity': quantity // quantity. calculated by endDate - startDate, length of stay

            });

        }

        ga('ecommerce:addTransaction', skdtransaction); // add transaction

        
        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.ecommerce:addTransaction';
            ga(gaEventState, skdtransaction); // add transaction
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.ecommerce:addTransaction';
            ga(gaEventAccoType, skdtransaction);
        }

        for (i = 0; i < items.length; i++) {
            ga('ecommerce:addItem', items[i]);

            if (trackingState !== ''){
                let gaEventState = trackingState.toLowerCase() + '.ecommerce:addItem';
                ga(gaEventState, items[i]);
            }


            if (trackingAccommodationType !== ''){
                let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.ecommerce:addItem';
                ga(gaEventAccoType, items[i]);
            }
        }

        ga('ecommerce:send');        //send ecommerce trackinginfo

        if (trackingState !== ''){
            let gaEventState = trackingState.toLowerCase() + '.ecommerce:send';
            ga(gaEventState);
        }

        if (trackingAccommodationType !== ''){
            let gaEventAccoType = trackingAccommodationType.toLowerCase() + '.ecommerce:send';
            ga(gaEventAccoType);
        }
        console.log("skd Log GA eCommerce resID ", data.reservationId);
    };
}