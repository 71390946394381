"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
// import Pristine from "pristinejs";
import {onEnterViewPort} from "@c0necto/elements-viewport-utils";
import {matches} from "@c0necto/elements-dom-utils";
import Pristine from "pristinejs";
import * as reloadForm from './reload-form';

const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options, selectors) {
    onEnterViewPort(form, function () {
        let pristine = new Pristine(form, options);
        form.addEventListener('submit', function (e) {
            if (!pristine.validate()){
                // e.stopImmediatePropagation();
                e.preventDefault();
            }
        });

        reloadForm.init({validator});

        function validator(form) {
            console.log('pristine',pristine)
            if(pristine && matches('.js-form-validation', form)){
                return pristine.validate();
            }else{
                return true;
            }
        }

    });
}

export function isValidationForm (form) {
    return matches('.js-form-validation', form);
}

export function isValid(form) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate();

    }else{
        return 'Using pristine without form';
    }
}