"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {find, hasClass, removeClass, addClass, closest, on} from "@c0necto/elements-dom-utils";

export function init () {
    onFind('.js-overlay__toggle', function (overlayToggle) {
        const overlayToggleHandler = evt => {

            let targetId = overlayToggle.getAttribute('data-overlay-toggle-target');
            let overlayItem = document.getElementById(targetId);

            if (hasClass('is-open', overlayItem)) {
                closeOverlay(overlayItem)
            } else {
                openOverlay(overlayItem)
            }
        };

        let mediaQuery = overlayToggle.getAttribute('data-media-query');

        if(!mediaQuery || matchMedia(mediaQuery).matches){
            on('click', overlayToggleHandler, overlayToggle);
        }

    });
}

export function openOverlay(overlay) {
    addClass('is-open', overlay);
    addClass('overlay-open', find('body'));

    console.log('overlay.getAttribute(\'id\')',overlay.getAttribute('id'))


    // exception for fullscreen quickfinder overlay on mobile and acco enquiry overlay
    if (overlay.getAttribute('id') !== 'quickfinderOverlay' && overlay.getAttribute('id') !== 'accommodationEnquiryOverlay'){
        window.addEventListener("click", function bodyClickListener(evt) {
            if (!overlay.contains(evt.target)) {
                removeClass('is-open', overlay);
                removeClass('overlay-open', find('body'));
                window.removeEventListener("click", bodyClickListener, true);
            }
        }, true);
    }


}

export function closeOverlay(overlay) {
    console.log('close overlay')
    removeClass('is-open', overlay);
    removeClass('overlay-open', find('body'));
}