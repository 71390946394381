import {find, findIn} from '@c0necto/elements-dom-utils'

export function init() {
    let searchModal = find('.js-modal-search');
    if(searchModal){
        let input  = findIn('.form-control', searchModal);

        searchModal.addEventListener('show.bs.modal', function(evt) {
            if (input) {
                setTimeout(function() {
                    input.value = '';
                    input.focus();
                    input.select();
                }, 200);
            }
        });
    }

}