/** @jsx h */

import {h, render} from "preact";
import AsyncSelect  from 'react-select/async'
import { components }  from 'react-select'
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {getPrefixedDataSet} from "@c0necto/elements-data-set-utils";
import {closest, find, addClass, removeClass, hasClass, trigger} from "@c0necto/elements-dom-utils";
import throwError from "@c0necto/elements-throw-error";
import {useState, useRef} from "preact/hooks";
import escapeHtml from "escape-html";
import 'whatwg-fetch'; // IE10 Polyfill
import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm';
import {translate} from '@c0necto/elements-translations';

const defaultSelectors = {
    base: '.js-typeahead'
};

const defaultOptions = {
    isClearable: true,
    defaultValue: ""
};

const Input = (props) => <components.Input {...props} isHidden={false} />;

let accoToggleTooltip;
let accoFilterCollapseBtn = find('.js-accommodation-filter__collapse-btn');

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        let options = {
            ...defaultOptions,
            ...options,
            ...transformDataSetOptions(getPrefixedDataSet('typeahead', baseElement))
        };

        render(<TypeaheadConfig isClearable={options.isClearable}
                                placeholder={options.placeholder}
                                value={options.defaultValue}
                                defaultIsOpen={false}
                                name={options.name}
                                minLength={options.minLength}
                                defaultOptions={options.options}
                                domElement={baseElement}/>,
            baseElement);
    });
}


function TypeaheadConfig({
        defaultOptions = [],
        value = "",
        placeholder = "",
        defaultIsOpen = false,
        name = "default-name",
        minLength = 0,
        domElement,
    isClearable
    }) {

    const [inputValue, setInputValue] = useState(value);
    const [additionalInputs, setAdditionalInputs] = useState(Object.entries(typeaheadDefaultValue.additionalInputs || {}));
    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    
    const loadData = (stringValue, callback) => {
        const _config = window._config || {};
        if (!_config.typeaheadDataUrl) {
            console.warn(`"_config.typeaheadDataUrl" is not set`);
        }

        let data = {
            query: stringValue
        };

        const url = addParamsToUrl(_config.typeaheadDataUrl, data);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(result => result.json()).then(result => {
            if (result.success === true) {
                callback(result.data.options);
            }
        }).catch(e => {
            console.error(`Could not load configuration data for typeahead`, e);
        });
    };

    const onFocus = () => {
        setInputValue(window.typeaheadDefaultValue.label);
    }

    const onInputChange = (newValue) => {
        let input = escapeHtml(newValue);
        setInputValue(newValue);

        if (input.length > +minLength) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
        return input;
    };

    const onChange = (values) => {
        // if values has link then change the action of the form for linking directly to the accommodation
        let form = closest('form', domElement);
        if(values){
            if(values.link){
                //check if ajax-form or normal form (portal)
                if(find('.js-ajax-form-map-leaflet')){
                    form.setAttribute('data-redirect-action', values.link);

                    if(accoFilterCollapseBtn){
                        addClass('disabled', accoFilterCollapseBtn);
                        if(!hasClass('collapsed',accoFilterCollapseBtn)){
                            trigger('click',accoFilterCollapseBtn)
                            removeClass('hide', find('.js-accommodation-filter__toggle-search'));
                        }
                        accoToggleTooltip = new Tooltip(find('.js-tooltip-acco-toggle'), {
                            title: find('.js-tooltip-acco-toggle').getAttribute('data-title')
                        });
                    }
                }else{
                    let form = closest('form', domElement);
                    form.setAttribute('action', values.link);
                }


            }else{
                form.removeAttribute('data-redirect-action');
                if(accoFilterCollapseBtn){
                    removeClass('disabled', accoFilterCollapseBtn);
                    if(accoToggleTooltip){
                        accoToggleTooltip.dispose();
                        find('.js-tooltip-acco-toggle').removeAttribute('title');
                    }
                }

            }

            setInputValue(values ? values.label : "");

            setAdditionalInputs(Object.entries(values.additionalInputs));
            window.typeaheadDefaultValue = values;
        }else{
            // Remove value

            setInputValue(values ? values.label : "");
            window.typeaheadDefaultValue = '';

            form.removeAttribute('data-redirect-action');
            if(accoFilterCollapseBtn){
                removeClass('disabled', accoFilterCollapseBtn);
                if(accoToggleTooltip){
                    accoToggleTooltip.dispose();
                    find('.js-tooltip-acco-toggle').removeAttribute('title');
                }
            }

            setAdditionalInputs([]);

        }

    };

    const styling = {
        control: styles => ({ ...styles, color:'#4c4c4a', backgroundColor: '#fff', borderWidth: '0' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = '#4c4c4a';
            return {
                ...styles,
                backgroundColor: isFocused ? '#faf4ea' : '#fff',
                color: isFocused ? '#4c4c4a' : '#4c4c4a',
                borderBottom: '1px solid #fff',
                cursor: isDisabled ? 'not-allowed' : 'cursor'
            }
        },
        menu: styles => ({ ...styles, backgroundColor: '#fff'}),
        loadingIndicator: styles => ({ ...styles, color:'#4c4c4a'}),

    };

    return (
        <div>
            <AsyncSelect
                name={name}
                inputValue={inputValue}
                defaultValue={typeaheadDefaultValue}
                className="react-select-wrapper"
                classNamePrefix="react-select"
                cacheOptions
                menuIsOpen={isOpen}
                loadOptions={isOpen ? loadData : ''}
                defaultOptions
                placeholder={placeholder}
                styles={styling}
                onInputChange={onInputChange}
                onChange={onChange}
                onFocus={onFocus}
                isClearable={true}
                noOptionsMessage={() => translate('filter.noResults')}
                loadingMessage={() => translate('filter.isLoading')}
                components={{
                    Input,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                }}
            />

            {additionalInputs.map(function (additionalInput) {
                return(
                    <HiddenInput className="react-select__input" name={additionalInput[0]} value={additionalInput[1]}></HiddenInput>
                    )
            })}

        </div>

    )

}


function HiddenInput(props){
    return (
        <input
            type="hidden"
            className="react-select__input"
            name={props.name}
            value={props.value}
        />
    )
}

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.options) {
        try {
            transformedOptions.options = JSON.parse(transformedOptions.options)
        } catch (e) {
            transformedOptions.options = null;
            throwError(OPTIONS_ERROR_MESSAGE);
        }
    }

    return transformedOptions;
}

export const addParamsToUrl = (url, params) =>
    url + (url.indexOf('?') >= 0 ? '&': '?')
    + Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');


const OPTIONS_ERROR_MESSAGE = `Typeahead error: data-typeahead-options has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.`;

