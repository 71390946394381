"use strict";

import {find,addClass,removeClass} from "@c0necto/elements-dom-utils";

export function init () {
    let scrollpos = window.scrollY;
    const nav = find('.js-affix-nav');
    if(nav){
        const navHeight = nav.offsetHeight;

        const addClassOnScroll = () => addClass('is-affix', nav);
        const removeClassOnScroll = () => removeClass('is-affix', nav);

        window.addEventListener('scroll', function() {
            scrollpos = window.scrollY;

            if (scrollpos >= navHeight) {
                addClassOnScroll();
            } else {
                removeClassOnScroll();
            }
        });
    }
}