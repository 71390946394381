import * as polyfills from "./scripts/polyfills/ie";

import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});


import './scripts/common';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
import {onFind} from "@c0necto/elements-init-modules-in-scope";

import * as lightbox from './scripts/lightbox';
lightbox.init({
    thumbnail:false,
    closable:true,
    closeOnTap: true
});
import * as imgTeaserSwiper from './scripts/img-teaser-swiper';

import * as accoTeaserSwiper from './scripts/acco-teaser-swiper';

import * as imageSwiper from './scripts/image-swiper';

import * as qualitySlideSwiper from './scripts/quality-slide-swiper';

import * as imgContentTeaserSwiper from './scripts/img-content-teaser-swiper';

import * as partnerSlideSwiper from './scripts/partner-slide-swiper';

import * as accoTeaserHoriSwiper from './scripts/acco-teaser-hori-swiper';

accoTeaserHoriSwiper.init();
partnerSlideSwiper.init();
imgContentTeaserSwiper.init();
qualitySlideSwiper.init();
imageSwiper.init();
accoTeaserSwiper.init();
imgTeaserSwiper.init();

// on('load', function() {
//     accoTeaserHoriSwiper.init();
//     partnerSlideSwiper.init();
//     imgContentTeaserSwiper.init();
//     qualitySlideSwiper.init();
//     imageSwiper.init();
//     accoTeaserSwiper.init();
//     imgTeaserSwiper.init();
//
// }, window);

import * as animationMoveUp from './scripts/animation-move-up';
animationMoveUp.init();

import * as datepicker from '@c0necto/elements-datepicker';
datepicker.init({
    locale: {
        firstDayOfWeek: 1
    }
});

// import * as datepickerRange from '@c0necto/elements-datepicker-range';
// datepickerRange.init();

import * as datepickerRange from "./scripts/datepicker-range";
onFind('.js-datepicker-range', function (baseElement) {
    datepickerRange.createDatepicker(
        baseElement,
        {
            /*options*/
            mode: "range",
            allowInput: true,
            showMonths: 2,
            disableMobile: "false",
            minDate: "today",
        },{
            base: '.datepicker-range-container',
            input: '.js-datepicker-range__input',
            altField: '.js-datepicker-range__alt-field',
            altFieldRangeTo: '.js-datepicker-range__range-to-alt-field'
        }
    );
});

import * as ajaxForm from '@c0necto/elements-ajax-form';
ajaxForm.init();

import * as video from './scripts/video';
video.init();

import * as keepDropdownOpen from './scripts/keep-dropdown-open';
keepDropdownOpen.init();

import * as quickfinderRoom from './scripts/quickfinder-room';
quickfinderRoom.init();

import * as overlayToggle from './scripts/overlay-toggle';
overlayToggle.init();

import * as leafletMap from './scripts/leaflet-map';
leafletMap.init();

import * as typeahead from './scripts/typeahead';
typeahead.init();

import * as formValidation from './scripts/form-validation';
import {on} from "@c0necto/elements-dom-utils";
formValidation.init();

import * as popover from './scripts/popover';
popover.init();

import * as seekdaTracking from './scripts/seekda-tracking';
seekdaTracking.init();
