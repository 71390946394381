"use strict";

import {findIn} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {

    onFind('.js-partner-slide-swiper', function (sliderElement) {

        createSwiper(sliderElement,{
                mediaQuery: '(max-width: 767px)',
                loop: false,
                slidesPerView: 1,
                spaceBetween: 40,
                pagination: {
                    el: swiperContainer => findIn('.js-partner-slide-swiper__pagination', swiperContainer),
                    clickable: true
                },
                on: {
                    init: function () {
                        setTimeout(() => {
                            window.dispatchEvent(new CustomEvent("resize", {bubbles: true}));
                        }, 200)
                    },
                }
            },
            {
                sliderContainer: '.js-partner-slide-swiper',
                slider: '.js-partner-slide-swiper__slider'
            });

    });
}