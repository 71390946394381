"use strict";

import {findIn} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {

    onFind('.js-img-teaser-swiper', function (sliderElement) {
        let dataSlidesPerView,
            dataMediaQuery = '(min-width: 1px)',
            slidesPerViewDesktop = 4,
            slidesPerViewMobile = 1.3;

        if(sliderElement.dataset.slidesPerView){
            dataSlidesPerView = JSON.parse(sliderElement.dataset.slidesPerView);
            slidesPerViewDesktop = dataSlidesPerView.desktop || 4;
            slidesPerViewMobile = dataSlidesPerView.mobile || 1.3;
        }

        if(sliderElement.dataset.mediaQuery){
            dataMediaQuery = sliderElement.dataset.mediaQuery;
        }

        createSwiper(sliderElement,{
                mediaQuery: dataMediaQuery,
                loop: false,
                slidesPerView: slidesPerViewMobile,
                spaceBetween: 20,
                pagination: {
                    el: swiperContainer => findIn('.js-img-teaser-swiper__pagination',sliderElement),
                    clickable: true
                },
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: slidesPerViewDesktop,
                        spaceBetween: 40,
                        allowTouchMove:false
                    }
                },
                on: {
                    init: function () {
                        setTimeout(() => {
                            window.dispatchEvent(new CustomEvent("resize", {bubbles: true}));
                        }, 200)
                    },
                }
            },
            {
                sliderContainer: '.js-img-teaser-swiper',
                slider: '.js-img-teaser-swiper__slider',
                sliderPrev: '.js-swiper__prev',
                sliderNex: '.js-swiper__next'
            })

    });

}