"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {findIn, on, closest} from "@c0necto/elements-dom-utils";

export function init () {
    onFind('form .toggle-password', function (button) {
        let input = findIn('[type=password]', closest('.form-group', button));
        on('click', (evt) => {
            evt.preventDefault();
            if(input.type === 'password') {
                input.type = 'text';
                button.classList.add('is-enabled');
            } else {
                input.type = 'password';
                button.classList.remove('is-enabled');
            }
        }, button);
    });
}