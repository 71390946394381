"use strict";

import {find, on} from "@c0necto/elements-dom-utils";
import {isValid} from "./form-validation";
import {onFind} from "@c0necto/elements-init-modules-in-scope";


export function init () {
    const copyHandler = (evt) => {
        dataLayerTracking({
            'event': 'gaTriggerCopiedText',
            'gaEventText': document.getSelection().toString()
        });
    }

    on('copy', (evt) => copyHandler(evt), find('body'));


    onFind('.js-tracking-form', function(form) {
        on('submit', (evt) => {
            if(isValid(evt.target)){
                console.log('is valid');
                dataLayerTracking({
                    'event': evt.target.getAttribute('data-tracking-event') || 'gaTriggerFormConversion'
                });
            }
        }, form);

    });

}

export function dataLayerTracking(data){
    if (dataLayer){
        dataLayer.push(data);
    }
}