"use strict";

import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {closest,hasClass, on} from "@c0necto/elements-dom-utils";
import {getConfigValue, requireConfigValues} from '@c0necto/elements-config-utils';
import {getPrefixedDataSet} from "@c0necto/elements-data-set-utils";
import {onEnterViewPort} from "@c0necto/elements-viewport-utils";
import 'whatwg-fetch'; // IE10 Polyfill

const configName = '_tracking';
const trackingViewUrl = getConfigValue('trackingView', configName),
      trackingClickUrl = getConfigValue('trackingClick', configName);


const defaultOptions = {
    view: false,
    click: false
};

const defaultSelectors = {
    base: '.js-custom-tracking'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    if(trackingViewUrl && trackingClickUrl) {
        onFind(selectors.base, function (baseElement) {
            createCustomTracking(
                baseElement,
                {...defaultOptions, ...options},
                {...defaultSelectors, ...selectors}
            );
        });
    }


    if (_tracking.trackingCustom){
        let trackingCustom = _tracking.trackingCustom;
        for (const [key, value] of Object.entries(trackingCustom)) {
            let dataKey = key,
                data = { [dataKey]: value };

            if (window['dataLayer'] && typeof window.dataLayer.push === 'function') {
                dataLayer.push(data);
            }

        }
    }
}

export function createCustomTracking(baseElement, defaultOptions, selectors) {

    let options = {
        ...defaultOptions,
        ...getPrefixedDataSet('custom-tracking', baseElement),
    };

    // View Tracking for element
    if(options.view){
        initViewTracking(baseElement, options);
    }


    // Click Tracking for elemnt
    if(options.click){
        initClickTracking(baseElement, options);
    }

}

function initViewTracking(trackingElement, options) {
    onEnterViewPort(trackingElement, function (element) {
        let trackingPost = fetch(trackingViewUrl, {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: options.dataJson
        });

        trackingPost.then(response => response.json())
            .then(result => {
                if (!result.success) {
                    console.error('Error in Custom Tracking');
                }
            }).catch(e => {
            console.warn(e);
        });

    }, { offset: -410 });
}


function initClickTracking(trackingElement, options) {

    const trackingElementClickHandler = evt => {

        // bei klick auf merkliste button nicht tracken
        if(!hasClass('btn-wishlist', evt.target) && !closest('.btn-wishlist', evt.target) && !hasClass('acco-pool-enquiry-btn', evt.target) && !closest('.acco-pool-enquiry-btn', evt.target)){
            let trackingPost = fetch(trackingClickUrl, {
                method: 'post',
                headers: {'Content-Type' : 'application/json'},
                body: options.dataJson
            });

            trackingPost.then(response => response.json())
                .then(result => {
                    if (!result.success) {
                        console.error('Error in Custom Tracking');
                    }
                }).catch(e => {
                console.warn(e);
            });
        }


    };

    on('click', trackingElementClickHandler, trackingElement);


}