"use strict";

import {find, findIn, findAll, addClass, hasClass, on, closest} from '@c0necto/elements-dom-utils'
import {initInScope, onFind,cleanUpInScope} from "@c0necto/elements-init-modules-in-scope";
import * as quickfinderBox from './quickfinder-box';

let roomCounter = 0;

let adultCounter = 0;
let childCounter = 0;
let firstInit = false;
let adultText = find('.js-quickfinder__adults-text'),
    childText = find('.js-quickfinder__child-text');


export function init() {
    let buttonAddRoom = find('.js-quickfinder__add-room');


    if (find('.js-room-template')){
        roomCounter = parseInt(find('.js-room-template').getAttribute('data-room-counter'));
    }

    if(buttonAddRoom){

        // only add initial room when its not prefilled
        if(roomCounter === 0){
            addRoom();
        }

        if(parseInt(adultText.innerHTML) > 3){
            adultCounter = adultCounter + parseInt(adultText.innerHTML);
        }

        quickfinderBox.init();

        onFind('.js-quickfinder-wrapper', function (quickfinderWrapper) {
            let addRoomBtn = findIn('.js-quickfinder__add-room', quickfinderWrapper);

            const addRoomClickHandler = (evt,) => {
                addRoom();
                let quickfinderDropdown = findIn('.js-quickfinder__dropdown',quickfinderWrapper);
                quickfinderDropdown.Dropdown.toggle();

                // if (!_config.portal){
                //     quickfinderDropdown.Dropdown.toggle();
                // }
            };

            on('click', (evt) => addRoomClickHandler(evt), addRoomBtn);

        });

        // Adult Input
        onFind('.js-quickinder__adult-input', function (adultInputs) {
            const adultChangeHandler = (evt) => {
                adultCounter = 0;

                findAll('.js-quickinder__adult-input').forEach((input) => {
                    if (input.value){
                        adultCounter = adultCounter + parseInt(input.value);
                    }else{
                        adultCounter = 0;
                    }
                });

                adultText.innerHTML = adultCounter;
            };

            on('change', (evt) => adultChangeHandler(evt), adultInputs);

        });

        // Child Input
        onFind('.js-quickfinder__child-input', function (childInputs) {
            const childChangeHandler = (evt) => {
                childCounter = 0;
                findAll('.js-quickfinder__child-input').forEach((input) => {
                    if (input.value){
                        childCounter = childCounter + parseInt(input.value);
                    }else{
                        childCounter = 0;
                    }
                });

                childText.innerHTML = childCounter;
            };

            // value of initial first adult select
            childCounter = parseInt(childInputs.value);

            on('change', (evt) => childChangeHandler(evt), childInputs);

        });

        // Remove Room Button
        onFind('.js-quickfinder__remove-room', function (removeBtn) {
            let quickfinderRemove = findAll('.js-quickfinder__remove-room');

            const removeRoomClickHandler = (evt) => {
                let roomWrapper = closest('.js-quickfinder__room', removeBtn);
                let quickfinderWrapper = closest('.js-quickfinder-wrapper', removeBtn);
                let quickfinderDropdown = findIn('.js-quickfinder__dropdown',quickfinderWrapper);
                let quickfinderRemove = findAll('.js-quickfinder__remove-room');

                roomWrapper.remove();

                let lastRemoveButton = quickfinderRemove[quickfinderRemove.length - 1];
                lastRemoveButton.removeAttribute('hidden');

                roomCounter = roomCounter-1;

                let thisAdultInput = findIn('.js-quickinder__adult-input',roomWrapper),
                    thisAdultInputVal = thisAdultInput.value;

                adultCounter = adultCounter - thisAdultInputVal;
                adultText.innerHTML = adultCounter;


                let thisChildInput = findIn('.js-quickfinder__child-input',roomWrapper),
                    thisChildInputVal = thisChildInput.value;

                childCounter = childCounter - thisChildInputVal;
                childText.innerHTML = childCounter;

                quickfinderDropdown.Dropdown.toggle();

            };

            for (let i = 0; i < quickfinderRemove.length -1; i++){
                quickfinderRemove[i].setAttribute('hidden', true);
            }

            on('click', (evt) => removeRoomClickHandler(evt), removeBtn);
        });

    }

}

export function addRoom() {
    let quickfinderRoomTemplate = findIn('.js-room-template__template',find('.js-room-template')),
        quickfinderRoomTarget = find('.js-room-template__target'),
        quickfinderWrapper = closest('.js-quickfinder-wrapper', quickfinderRoomTemplate);

    if(quickfinderRoomTemplate) {
        let markupElementRoom = document.createElement('div');
        markupElementRoom.innerHTML = quickfinderRoomTemplate.innerHTML;

        let roomCounterEl = findIn('.js-quickfinder__room-counter', markupElementRoom);

        if (roomCounterEl){
            roomCounterEl.innerHTML = roomCounter+1;
        }

        let childInputchildInput = findIn('.js-quickfinder__child-input', markupElementRoom);
        let adultInputchildInput = findIn('.js-quickinder__adult-input', markupElementRoom);
        childInputchildInput.id = `childs-` + roomCounter;
        childInputchildInput.name = `childs-` + roomCounter;
        adultInputchildInput.id = `adults-` + roomCounter;
        adultInputchildInput.name = `adults-` + roomCounter;

        roomCounter = roomCounter+1;

        quickfinderWrapper.setAttribute('data-room-counter', roomCounter);

        quickfinderRoomTarget.insertAdjacentHTML('beforeend', `<div class="js-quickfinder__room quickfinder__room" data-item-room-count=`+ roomCounter +`>` + markupElementRoom.innerHTML + `</div>`);

        adultCounter = adultCounter +2
        adultText.innerHTML = adultCounter;

        initInScope(quickfinderWrapper);
        cleanUpInScope(quickfinderWrapper);

        setTimeout(function(){

            if (roomCounter <= 1 && !firstInit){
            document.querySelector('#adults-0').value='2';
                firstInit = true;
        }

        }, 1000);

    }
}
